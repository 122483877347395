export interface DocumentHistory {
  id: string;
  caseId: string;
  documentType: string;
  userAction: string;
  status: string;
  createdBy:string;
  created: Date;

  props?: { [key: string]: any };
}

export interface EmailHistory {
  id: string;
  documentActionHistoryId:string;
  recipients: string;
  emailMessage: string;
  status: string;
  processDate:Date;
  error:string

}

export enum DocumentActions {
  PdfView = 'PdfView',
  PdfDownload='PdfDownload',
  Email='Email'
}

export enum DocumentType {
  EmployerForm = 'EmployerForm',
  ProviderForm = 'ProviderForm',
  EmployeeForm = 'EmployeeForm',
  AlertForm = 'AlertForm',
  DocumentsForProvider = 'DocumentsForProvider'
}
export const DocumentTypeDescriptions: Record<DocumentType, string> = {
  [DocumentType.EmployerForm]: 'Employer Form',
  [DocumentType.ProviderForm]: 'Provider Form',
  [DocumentType.EmployeeForm]: 'Employee Form',
  [DocumentType.AlertForm]: 'Alert Form',
  [DocumentType.DocumentsForProvider]: 'Documents for Provider'
};
//export type DocumentActionsTypes = typeof DocumentActions
